<template>
  <b-card-code no-body>
    <b-card-body>
      <!-- Pesquisa -->
      <b-row>
        <b-col md="2">
          <b-form-group label="Mês" label-for="mes">
            <b-form-select id="mes" v-model="pesquisar.mes" :options="mesOpcoes" />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="Ano" label-for="ano">
            <v-select-pt v-model="pesquisar.exercicio" name="ano" :options="anoOpcao" label="exercicio" />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Órgão" label-for="orgao">
            <v-select-pt
              v-model="pesquisar.orgao"
              name="orgao"
              :options="orgaoOpcao"
              :reduce="(option) => option.id"
              label="nome"
              @option:selected="carregarGrid()"
              @keydown.enter="carregarGrid"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input id="nome" v-model="pesquisar.nome" />
          </b-form-group>
        </b-col>

        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="isBusy"
            variant="primary"
            style="margin-top: 25px; width: 160px"
            @click="carregarGrid()"
          >
            {{ !isBusy ? 'Filtrar' : '' }}
            <b-spinner v-if="isBusy" small />
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="auto">
          <b-dropdown
            :disabled="items.length == 0"
            variant="link"
            no-caret
            toggle-class="p-0"
            left
            :popper-opts="{ positionFixed: true }"
          >
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="margin-top: 21px; width: 160px"
                variant="primary"
                class="btn-icon"
              >
                Integração
                <feather-icon icon="ChevronDownIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="autenticaEEnviaSisprev()">
              <svg id="logoAgenda" width="18" height="20" viewBox="0 0 103 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M102 2L2 101.495H102V2Z" fill="#3F278D" stroke="#3F278D" />
                <path d="M56 58.5657L13.5 102H56V58.5657Z" fill="#F8F64B" />
              </svg>
              <span class="align-middle ml-50">Sisprev Web</span>
            </b-dropdown-item>

            <b-dropdown-item @click="exportarCSV()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">.CSV</span>
            </b-dropdown-item>

            <b-dropdown-item @click="exportarTxt()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">.TXT</span>
            </b-dropdown-item>

            <b-dropdown-item @click="downloadLayout()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">Layout CSV e TXT</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col md="auto">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="selectedItems.length == 0"
            variant="primary"
            style="margin-top: 21px; width: 200px"
            @click.prevent="confirmaBaixaManual"
          >
            Baixa de Parcela(s)
            <b-spinner v-if="isBusy" small />
          </b-button>
        </b-col>

        <b-col md="auto">
          <b-dropdown
            :disabled="items.length == 0"
            variant="link"
            no-caret
            toggle-class="p-0"
            left
            :popper-opts="{ positionFixed: true }"
          >
            <template #button-content>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                style="margin-top: 21px; width: 300px"
                variant="primary"
                class="btn-icon"
              >
                Extrato de Parcelas à Descontar
                <feather-icon icon="ChevronDownIcon" />
              </b-button>
            </template>

            <b-dropdown-item @click="gerarRelatorioPDF">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">PDF</span>
            </b-dropdown-item>

            <b-dropdown-item @click="exportToExcel()">
              <feather-icon icon="FileTextIcon" size="16" />
              <span class="align-middle ml-50">Excel</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <b-col md="auto">
          <!-- :disabled="selectedItems.length == 0" -->
          <b-button
            id="btn-historico-integracao"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            style="margin-top: 21px; width: 240px"
            @click.prevent="abreModalHistoricoIntegracao"
          >
            Histórico de Integração
            <b-spinner v-if="isBusy" small />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card v-show="this.totalRows === 0">
      <h3 class="text-black text-center mb-2">Não foi encontrado nenhum lançamento!</h3>
    </b-card>

    <span class="m-1"
      >Total de Lançamentos Selecionados:
      <b-badge pill>
        {{ formatarValor(somatoriaValores) }}
      </b-badge>
    </span>
    <span class="m-1"
      >Total de Contratos Selecionados:
      <b-badge pill>
        {{ this.selectedItems.length }}
      </b-badge>
    </span>

    <!-- Tabela -->
    <b-table
      v-show="items.length > 0"
      id="tabelaLancamentos"
      ref="tabelaGrid"
      small
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
      select-mode="multi"
      :selected-variant="'primary'"
      :selected.sync="selectedItems"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #head(check)="row">
        <b-form-checkbox v-model="selectAll" style="display: inline-block" @change="selectAllRows" />
        Baixar Todos
      </template>
      <template #cell(check)="row">
        <b-form-checkbox
          v-if="row.item.statusDesconto != 'Descontado'"
          v-model="selectedItems"
          :value="row.item.id"
          :name="'row-' + row.index"
          @change="adicionaContadorSelecionado(row.item)"
        />
      </template>

      <template #cell(nomeCompleto)="row">
        {{ row.item.financeiro.segurado.tomador.nomeCompleto }}
      </template>

      <template #cell(cpf)="row">
        {{ row.item.financeiro.segurado.tomador.cpf }}
      </template>

      <template #cell(dataNascimento)="row">
        {{ formatarData(row.item.dataNascimento) }}
      </template>

      <template #cell(matricula)="row">
        {{ row.item.financeiro.segurado.matricula }}
      </template>

      <template #cell(categoria)="row">
        {{ row.item.financeiro.segurado.situacaoPrevidenciaria }}
      </template>

      <template #cell(valorTotalParcela)="row">
        {{ formatarValor(row.item.valor) }}
      </template>

      <template #cell(descontoRealizado)="row">
        {{ row.item.descontoRealizado ? formatarValor(row.item.descontoRealizado) : formatarValor(0) }}
      </template>

      <template #cell(valorAPagar)="row">
        {{ formatarValor(row.item.valor - row.item.descontoRealizado) }}
      </template>

      <template #cell(contratoNumero)="row">
        {{ row.item.financeiro.codigo }}
      </template>

      <template #cell(periodo)="row">
        {{ String(row.item.competencia).substring(4, 6) + '/' + String(row.item.competencia).substring(0, 4) }}
      </template>

      <template #cell(status)="row">
        <b-badge :variant="statusMap[row.item.statusDesconto][1]">
          {{ statusMap[row.item.statusDesconto][0] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <b-modal
      id="modal-cadastro"
      ref="modal-cadastro"
      centered
      size="lg"
      title="Cadastro do Tomador"
      no-close-on-backdrop
      hide-footer
    >
      <modalCadastroTomador v-if="cadastroTomador" :parametro="cadastroTomador" />
    </b-modal>

    <!-- Contrato -->
    <b-modal id="modal-contrato-consolidado" ref="modal-contrato-consolidado" centered size="xl" hide-header hide-footer>
      <contratoContrato v-if="contrato" :parametro="contrato" />
    </b-modal>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>

    <b-modal
      id="baixaParcelas"
      ref="modal-baixa-parcela"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      title="Baixa de Parcela(s)"
      @confirmarBaixa="autenticarMethod"
    >
      <b-row>
        <b-col md="12">
          <b-form-group v-slot="{ ariaDescribedby }" label="Tipo de Baixa">
            <b-form-radio-group
              id="btn-radios-2"
              v-model="tipoBaixa"
              :options="baixaOpcoes"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="md"
              name="radio-btn-outline"
              buttons
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="tipoBaixa == 'PagaParcialmente'" class="mt-2">
        <b-col md="6">
          <b-form-group label="Valor da Parcela" label-for="valorDaParcela">
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">R$</span>
              </b-input-group-prepend>
              <b-form-input
                id="valorDaParcela"
                v-model="valorDaParcela"
                v-number="number"
                class="form-control"
                type="text"
                placeholder="Informe o Valor da Parcela"
                :raw="false"
                :disabled="true"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Valor da Baixa Parcial" label-for="valorBaixaParcial">
            <validation-provider #default="{ errors }" name="Valor da Baixa Parcial" rules="required">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">R$</span>
                </b-input-group-prepend>
                <b-form-input
                  id="valorBaixaParcial"
                  v-model="valorBaixaParcial"
                  v-number="number"
                  class="form-control"
                  type="text"
                  placeholder="Informe o Valor da Baixa Parcial"
                  :raw="false"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="12">
          <b-button style="float: right" variant="primary" @click="autenticaEConfirmaPagamento"> OK </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Exportação de PDF -->
    <div>
      <ExportarPDFParcelasNaoDescontadas ref="exportarPDF" :parametro="parametroPDF" />
    </div>

    <!-- Modal Histórico Importação -->
    <b-modal
      id="modal-historico-importacao"
      ref="modal-historico-importacao"
      title="Histórico de Importação"
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <ModalHistoricoImportacao :parametro="parametroModalHistorico" />
    </b-modal>
  </b-card-code>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarParaNumber, formatarValor } from '@/libs/utils'
  import modalCadastroTomador from '@/views/components/Tomador/modalCadastroTomador.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import { asDefault } from '@/views/pages/relatorio/exportTable'
  import lancamentoQuitacao from '../lancamentoQuitacao.vue'

  // Relatórios
  import ExportarPDFParcelasNaoDescontadas from '../relatorios/ExportarPDFParcelasNaoDescontadas.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'

  import ModalHistoricoImportacao from './ModalHistoricoImportacao.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      modalCadastroTomador,
      lancamentoQuitacao,
      ToastificationContent,
      formatarValor,
      formatarData,
      AutenticaSenha,
      ExportarPDFParcelasNaoDescontadas: ExportarPDFParcelasNaoDescontadas,
      asDefault,
      formatarParaNumber,
      generateAndDownloadPdf,
      ModalHistoricoImportacao,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        nomeRelatorio: 'Extrato de Parcelas Não Descontadas',
        selectAll: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        formatarData,
        formatarValor,
        formatarParaNumber,
        number: {},
        statusValor: undefined,
        statusMap: {
          Descontado: ['Parcela Descontada', 'success'],
          Rejeitada: ['Parcela Rejeitada', 'danger'],
          PagaParcialmente: ['Paga Parcialmente', 'warning'],
          NaoDescontada: ['Não Descontada', 'secondary'],
        },
        statusOpcao: [
          { value: 'Rejeitada', text: 'Parcelas Rejeitadas' },
          { value: 'PagaParcialmente', text: 'Parcelas Pagas Parcialmente' },
          { value: 'NaoDescontada', text: 'Parcelas Não Descontadas' },
        ],
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        baixaOpcoes: [
          { value: 'Descontado', text: 'Parcela Descontada' },
          { value: 'Rejeitada', text: 'Parcela Rejeitada' },
          { value: 'PagaParcialmente', text: 'Paga Parcialmente' },
        ],
        orgaoOpcao: [],
        anoOpcao: [],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pendente', text: 'Pendente' },
          { value: 'Consolidado', text: 'Consolidado' },
        ],
        pesquisar: {
          perPage: 10,
          mes: new Date().getMonth() + 1, // Mês atual
          exercicio: new Date().getFullYear(), // Ano atual
          currentPage: 1,
        },
        required,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        mode: '',
        sortDirection: 'asc',
        baseFields: [
          { key: 'check', label: 'Baixar' },
          { key: 'competencia', label: 'Competência' },
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'contratoNumero', label: 'Nº Do Contrato', sortable: true },
          { key: 'valorTotalParcela', label: 'Valor Da Parcela', sortable: true },
          { key: 'orgaoNome', label: 'Órgão', sortable: true },
        ],
        fields: [
          { key: 'check', label: '', thStyle: { width: '150px' } },
          { key: 'competencia', label: 'Competência' },
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'contratoNumero', label: 'Nº Do Contrato', sortable: true },
          { key: 'valorTotalParcela', label: 'Valor Da Parcela', sortable: true },
          { key: 'orgaoNome', label: 'Órgão', sortable: true },
        ],
        borderless: true,
        statusGRPE: [
          {
            GeradoGRPE: 'success',
            NaoGeradoGRPE: 'warning',
            null: 'warning',
          },
        ],
        statusLancamento: [
          {
            Lancado: 'success',
            null: 'warning',
            NaoLancado: 'warning',
          },
        ],
        items: [],
        finanitems: [],
        lancamento: [],
        parc: [],
        prop: [],
        vouPesquisar: false,
        contrato: null,
        usersUrl: 'https://jsonplaceholder.typicode.com/users',
        photosUrl: 'https://jsonplaceholder.typicode.com/photos',
        acesso: {},
        cadastroTomador: [],
        selectedItems: [],
        habilitarGRPE: false,
        somatoriaValores: 0,
        valorDaParcelaValidacao: 0,
        desabilitarLancGRPE: false,
        tipoBaixa: 'Descontado',
        valorBaixaParcial: null,
        valorDaParcela: null,

        // Relatório PDF
        colunasTabelas: [],
        parametroPDF: {
          dados: [],
          colunas: [],
          total: 0,
        },
        fieldsPDF: [
          { key: 'competencia', label: 'Competência' },
          { key: 'parcelaAtual', label: 'Nº da Parcela' },
          { key: 'matricula', label: 'Nº Matrícula' },
          { key: 'nomeCompleto', label: 'Nome' },
          { key: 'cpf', label: 'CPF' },
          { key: 'contratoNumero', label: 'Nº Do Contrato' },
          { key: 'valorTotalParcela', label: 'Valor Da Parcela' },
          { key: 'orgaoNome', label: 'Órgão' },
        ],
        layoutTxt: {
          cpf: {
            tipo: 'string',
            tamanho: '14',
            preencheComEspaço: true,
            exemplo: '056.336.861-60',
          },
          matricula: {
            tipo: 'string',
            tamanho: '10',
            preencheComEspaço: true,
            exemplo: '0005',
          },
          valorParcela: {
            tipo: 'int',
            tamanho: '16',
            preencheComEspaço: true,
            exemplo: 3585.48,
          },
          parcelaAtual: {
            tipo: 'string',
            tamanho: '7',
            preencheComEspaço: true,
            exemplo: '001/120',
          },
          totalParcela: {
            tipo: 'int',
            tamanho: '3',
            preencheComEspaço: true,
            exemplo: 48,
          },
          nrContrato: {
            tipo: 'string',
            tamanho: '8',
            preencheComEspaço: true,
            exemplo: '005/2023',
          },
          idConsigPrev: {
            tipo: 'int',
            tamanho: '10',
            preencheComEspaço: true,
            exemplo: 2115,
          },
          ano: {
            tipo: 'int',
            tamanho: '4',
            preencheComEspaço: true,
            exemplo: 2023,
          },
          mes: {
            tipo: 'int',
            tamanho: '2',
            preencheComEspaço: true,
            exemplo: 10,
          },
          descontado: {
            tipo: 'boolean',
            tamanho: '5',
            preencheComEspaço: true,
            exemplo: false,
          },
          motivoDoNaoDesconto: {
            tipo: 'string',
            tamanho: '100',
            preencheComEspaço: true,
            exemplo: 'Segurado não possui margem disponível',
          },
        },

        // Historico de Integração
        parametroModalHistorico: {
          competencia: null,
          institutoId: null,
        },
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    watch: {
      statusValor(val) {
        if (val == 'Descontado') {
          this.fields = [
            ...this.baseFields.filter((f) => {
              if (f.key == 'valorAPagar') {
                return false
              }
              return true
            }),
          ]
        } else if (val == 'Rejeitada') {
          this.fields = [
            ...this.baseFields.filter((f) => {
              if (f.key == 'valorTotalParcela' || f.key == 'descontoRealizado') {
                return false
              }
              return true
            }),
          ]
        } else if (val == 'PagaParcialmente') {
          this.fields = [...this.baseFields]
        }
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroContratosPendentes = true
        this.acesso.FinanceiroContratosConsolidados = true
      } else {
        this.acesso.FinanceiroContratosPendentes = this.userData.GrupoAcesso.FinanceiroContratosPendentes
        this.acesso.FinanceiroContratosConsolidados = this.userData.GrupoAcesso.FinanceiroContratosConsolidados
      }

      this.carregaAno()
      this.carregarOrgaos()
    },
    methods: {
      selectAllRows(checked) {
        this.somatoriaValores = 0
        this.selectedItems = []
        if (checked) {
          for (const i in this.items) {
            this.selectedItems.push(this.items[i].id)
            this.somatoriaValores += formatarParaNumber(this.items[i].valor)
          }
        }
      },
      async carregarGrid() {
        try {
          this.isBusy = true
          this.totalRows = 0
          this.pesquisar.currentPage = 1

          const parcelas = await useJwt.post('lancamento/getParcelasNaoDescontadas', {
            mes: this.pesquisar.mes,
            ano: this.pesquisar.exercicio,
            orgaoId: this.pesquisar.orgao,
            tomadorNome: this.pesquisar.nome,
            institutoId: this.userData.institutoSelecionado,
          })
          this.selectedItems = []

          this.items = parcelas.data
          this.items.sort((a, b) =>
            a.financeiro.segurado.tomador.nomeCompleto.localeCompare(b.financeiro.segurado.tomador.nomeCompleto),
          )
          this.totalRows = this.items.length

          this.linhasTabela = this.items // .filter(item => item.statusDesconto == "NaoDescontada");
          this.dividirLista(this.linhasTabela, 12)

          let competencia = ''
          let orgaoNome = ''

          if (this.pesquisar.mes) {
            competencia = `${String(this.mesOpcoes.find((item) => item.value == this.pesquisar.mes).text)}/${String(
              this.pesquisar.exercicio,
            )}`
          }
          if (this.pesquisar.orgao) {
            orgaoNome = this.orgaoOpcao.find((item) => item.id == this.pesquisar.orgao).nome
          }

          // Monta variável global de relatório PDF
          let dadosPDF = this.items.map((item) => {
            return {
              parcelaAtual: item.parcelaAtual,
              matricula: item.financeiro.segurado.matricula,
              nomeCompleto: item.financeiro.segurado.tomador.nomeCompleto,
              cpf: item.financeiro.segurado.tomador.cpf,
              contratoNumero: item.financeiro.codigo,
              valorTotalParcela: formatarValor(item.valor),
              competencia: item.competencia,
              orgaoNome: item.orgaoNome,
            }
          })
          let valorTotal = this.items.reduce((acc, item) => acc + formatarParaNumber(item.valor), 0)
          ;(this.colunasTabelas = this.fieldsPDF.map((item) => ({
            label: item.label,
            key: item.key,
          }))),
            // Monta variável global de relatório PDF
            (this.parametroPDF = {
              dados: dadosPDF,
              colunas: this.colunasTabelas,
              institutoNome: this.userData.Instituto.nome,

              // Configura totalizadores
              totalizadores: {
                valorTotal,
              },
            })
        } catch (error) {
          console.error(`Erro: ${error}`)
        }
        this.isBusy = false
      },
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.orgaoOpcao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregaAno() {
        const anoAtual = new Date().getFullYear()
        const anoInicio = 2019
        const anosFuturo = 20

        const listaAnos = []
        for (let i = anoInicio; i <= anoAtual + anosFuturo; i++) {
          listaAnos.push(String(i))
        }

        listaAnos.sort((a, b) => a - b)

        this.anoOpcao = listaAnos
      },
      async realizarEnvioParaSisprev() {
        const listaDescontada = this.items.filter((parcela) => parcela.statusDesconto == 'NaoDescontada')
        const parametroEnvioSisprev = {
          ano: this.pesquisar.exercicio,
          mes: this.pesquisar.mes,
          lista: listaDescontada,
          usuario: this.userData.name,
          institutoId: this.userData.institutoSelecionado,
          ambienteApi: this.userData.Instituto.ambienteApi,
        }

        try {
          const retornoEnvio = await useJwt.post('lancamento/RealizaEnvioDescontoParaSisprev', parametroEnvioSisprev)
          this.$message.success('Descontos enviados com sucesso!')
          this.$refs['modal-auth-senha'].hide()
        } catch (error) {
          console.error(error)
          this.$message.error('Erro ao enviar descontos para o Sisprev!')
        }
      },
      async realizarBaixaManual() {
        const parametro = {
          lista: this.selectedItems,
          status: this.tipoBaixa,
          valorParcial: this.valorBaixaParcial,
        }
        const retorno = await useJwt.put('lancamento/BaixaLancamentosManualmente', parametro)

        this.carregarGrid()

        if (retorno.data.status == 'sucesso') this.$message.success(retorno.data.mensagem)
        if (retorno.data.status == 'erro') this.$message.error(retorno.data.mensagem)

        this.$refs['modal-auth-senha'].hide()
        this.$refs['modal-baixa-parcela'].hide()

        this.valorDaParcela = 0
        this.valorBaixaParcial = 0
        this.somatoriaValores = 0
        this.valorDaParcelaValidacao = 0
        this.selectedItems = []
      },
      async adicionaContadorSelecionado() {
        this.somatoriaValores = 0

        this.selectedItems.forEach((item) => {
          const parcela = this.items.find((lancamento) => lancamento.id == item)
          this.somatoriaValores += formatarParaNumber(parcela.valor)
        })
      },
      autenticarMethod(data) {
        if (data) {
          if (this.mode == 'enviaParaSisprev') this.realizarEnvioParaSisprev()

          if (this.mode == 'baixa') this.realizarBaixaManual()
        }
      },
      autenticaEBaixa() {
        this.mode = 'baixa'
        this.$refs['modal-auth-senha'].show()
      },
      autenticaEConfirmaPagamento() {
        if (!formatarParaNumber(this.valorBaixaParcial) && this.tipoBaixa == 'PagaParcialmente') {
          this.$message.error('Digite um valor para baixa parcial antes de prosseguir.')
          return
        }

        if (
          formatarParaNumber(this.valorBaixaParcial) > formatarParaNumber(this.valorDaParcela) &&
          this.tipoBaixa == 'PagaParcialmente'
        ) {
          this.$message.error('Valor da baixa parcial não pode ser superior ao valor da parcela!')
          return
        }

        if (
          formatarParaNumber(this.valorBaixaParcial) == formatarParaNumber(this.valorDaParcela) &&
          this.tipoBaixa == 'PagaParcialmente'
        ) {
          this.$message.error('Valor da baixa parcial não pode ser igual ao valor da parcela!')
          return
        }

        this.mode = 'baixa'
        this.$refs['modal-auth-senha'].show()
      },
      confirmaBaixaManual() {
        // limpa a váriavel para que os campos não sejam apresentados ao selecionar mais de um lançamento.
        // this.somatoriaValores = 0
        this.valorDaParcela = 0
        this.valorBaixaParcial = 0
        this.valorDaParcelaValidacao = 0
        this.tipoBaixa = null

        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }

        this.baixaOpcoes = [
          { value: 'Descontado', text: 'Parcela Descontada' },
          { value: 'Rejeitada', text: 'Parcela Rejeitada' },
        ]

        if (this.selectedItems.length == 1) {
          this.valorDaParcela = null
          this.baixaOpcoes.push({ value: 'PagaParcialmente', text: 'Paga Parcialmente' })
          this.selectedItems.forEach((item) => {
            const valorParcela = this.items.find((lancamento) => lancamento.id == item)
            this.valorDaParcela += formatarParaNumber(valorParcela.valor)
          })

          this.valorDaParcelaValidacao = this.valorDaParcela

          if (this.valorDaParcela) {
            this.valorDaParcela = String(this.valorDaParcela).includes('.')
              ? Number(this.valorDaParcela)
              : Number(this.valorDaParcela) * 100
          }
        }

        this.$refs['modal-baixa-parcela'].show()
      },
      autenticaEEnviaSisprev() {
        this.mode = 'enviaParaSisprev'
        this.$refs['modal-auth-senha'].show()
      },
      gerarRelatorioPDF() {
        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }

        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            null,
            nomeRelatorio,
            informacoesInstituto,
            true,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      dividirLista(listaOriginal, tamanhoMaximo) {
        const primeiraPagina = listaOriginal.slice(0, tamanhoMaximo)
        const restante = listaOriginal.slice(tamanhoMaximo)
        const sublistas = [primeiraPagina]
        let sublistaAtual = []

        restante.forEach((item, indice) => {
          if (sublistaAtual.length < tamanhoMaximo) {
            sublistaAtual.push(item)
          } else {
            sublistas.push(sublistaAtual)
            sublistaAtual = [item]
          }

          if (indice === restante.length - 1) {
            sublistas.push(sublistaAtual)
          }
        })

        this.linhasTabela = sublistas
      },
      transformarJson(jsonData) {
        const resultado = []

        jsonData.forEach((item) => {
          const { id, parcelaAtual, valor, competencia, financeiro } = item

          const { segurado } = financeiro

          const { cpf } = segurado.tomador

          resultado.push({
            nomeCompleto: segurado.tomador.nomeCompleto,
            cpf,
            matricula: segurado.matricula,
            valorParcela: parseFloat(valor.replace(',', '.')),
            parcelaAtual: String(parcelaAtual).padStart(6, '0'),
            totalParcela: parseInt(parcelaAtual.split('/')[1], 10),
            nrContrato: financeiro.codigo,
            idConsigPrev: id.toString(),
            ano: Math.floor(competencia / 100),
            mes: competencia % 100,
          })
        })

        // Adiciona totalizador de valor de parcela e de quantidade de parcelas totais
        resultado.push({
          nomeCompleto: 'TOTAL',
          cpf: '',
          matricula: '',
          valorParcela: resultado.reduce((acc, item) => acc + formatarParaNumber(item.valorParcela), 0),
          parcelaAtual: '',
          totalParcela: resultado.reduce((acc, item) => acc + formatarParaNumber(item.totalParcela), 0),
          nrContrato: '',
          idConsigPrev: '',
          ano: '',
          mes: '',
        })

        return resultado
      },
      exportarCSV() {
        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }
        const listaExportacao = this.transformarJson(this.items)
        const arquivoCsv = this.jsonToCsv(listaExportacao)
        this.gerarCsv(arquivoCsv)
      },
      gerarCsv(arquivoCsv) {
        const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(arquivoCsv)}`
        const link = document.createElement('a')
        link.setAttribute('href', csvData)
        link.setAttribute('download', `${String(this.pesquisar.exercicio) + String(this.pesquisar.mes)}.csv`)
        document.body.appendChild(link)
        link.click()
      },
      jsonToCsv(jsonData) {
        const csvRows = []

        // Cria o cabeçalho CSV com ponto e vírgula como separador
        const headers = Object.keys(jsonData[0])
        csvRows.push(headers.join(';'))

        // Itera sobre os objetos JSON e converte em linhas CSV com ponto e vírgula como separador
        for (const item of jsonData) {
          const values = headers.map((header) => {
            const value = item[header]
            return typeof value === 'string' ? `"${value}"` : value
          })
          csvRows.push(values.join(';'))
        }

        // Combina todas as linhas em uma única string
        const csvString = csvRows.join('\n')

        // Retorna a string CSV
        return csvString
      },
      jsonToTxt(jsonData) {
        const txtRows = []

        jsonData.forEach((item) => {
          const propriedade = Object.keys(item)
          const row = []

          propriedade.forEach((propriedades) => {
            let tamanho

            if (this.layoutTxt[propriedades]) tamanho = Number(this.layoutTxt[propriedades].tamanho)

            row.push(String(item[propriedades]).padEnd(tamanho))
          })

          row.push('\n')
          txtRows.push(row.join(''))
        })

        return txtRows.join('')
      },
      downloadTxt(jsonData) {
        const txtData = this.jsonToTxt(jsonData)

        const blob = new Blob([txtData], { type: 'text/plain' })
        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = `${String(this.pesquisar.exercicio) + String(this.pesquisar.mes)}.txt`
        document.body.appendChild(link)
        link.click()

        // Libere o objeto Blob e o URL temporário
        window.URL.revokeObjectURL(url)
      },
      exportarTxt() {
        if (!this.pesquisar.orgao) {
          this.$message.error('Selecione um órgão para realizar a baixa!')
          return
        }

        const listaExportacao = this.transformarJson(this.items)
        this.downloadTxt(listaExportacao)
      },
      exportToExcel() {
        const listaExportacao = this.transformarJson(this.items)
        asDefault({ fileName: `${String(this.pesquisar.exercicio) + String(this.pesquisar.mes)}` }).export(listaExportacao)
      },
      downloadLayout() {
        const layoutImportacaoMargem = `Layout de Dados - Informações de lançamento

  Este arquivo contém informações detalhadas sobre lançamentos. Cada campo é estruturado de acordo com o tipo de dado, tamanho e um exemplo correspondente.

  Campos disponíveis:

  cpf:           (String - Tamanho: 14 caracteres)          Exemplo: "056.313.841-60"
  matricula:     (String - Tamanho: 10 caracteres)          Exemplo: "0005"
  valorParcela:  (Número Decimal - Tamanho: 16 caracteres)  Exemplo: 3585.48
  parcelaAtual:  (String - Tamanho: 7 caracteres)           Exemplo: "001/120"
  totalParcela:  (Número Inteiro - Tamanho: 3 caracteres)   Exemplo: 48
  nrContrato:    (String - Tamanho: 8 caracteres)           Exemplo: "005/2023"
  idConsigPrev:  (Número Inteiro - Tamanho: 10 caracteres)  Exemplo: 2115
  ano:           (Número Inteiro - Tamanho: 4 caracteres)   Exemplo: 2023
  mes:           (Número Inteiro - Tamanho: 2 caracteres)   Exemplo: 10
  descontado:    (Boolean - Tamanho: 5 caracteres)          Exemplo: ""
  motivoDoNaoDesconto: (String - Tamanho: 100 caracteres)   Exemplo: ""`

        // let layoutImportacaoMargemText = JSON.stringify(layoutImportacaoMargem)
        const element = document.createElement('a')
        element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(layoutImportacaoMargem)}`)
        element.setAttribute('download', 'Layout Exportação de Lançamentos.txt')

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
      },
      abreModalHistoricoIntegracao() {
        this.parametroModalHistorico = {
          competencia: Number(this.pesquisar.exercicio) * 100 + Number(this.pesquisar.mes),
          institutoId: Number(this.userData.institutoSelecionado),
        }
        this.$refs['modal-historico-importacao'].show()
      },
    },
  }
</script>

<style lang="scss">
  #tabelaLancamentos > thead > tr > th {
    // padding: 0.52rem 0.7rem;
    // padding-top: 0.52rem;
    // padding-right: 0.7rem;
    // padding-bottom: 0.52rem;
    // padding-left: 0.7rem;
    vertical-align: middle !important;
    text-align: center;
  }

  #tabelaLancamentos > tbody > tr > td {
    // padding: 0.52rem 0.7rem;
    // padding-top: 0.52rem;
    // padding-right: 0.7rem;
    // padding-bottom: 0.52rem;
    // padding-left: 0.7rem;
    vertical-align: middle !important;
    text-align: center;
  }
</style>
